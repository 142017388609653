:root {
  // main
  --nam-color-primary: #2687FD;
  --nam-color-primary-opaque: #306ad91a;
  --nam-color-secondary: #162737;

  // other colors
  --nam-color-white: #fff;
  --nam-color-black: #000;

  // grey
  --nam-color-light-grey: #b6b9bc;
  --nam-color-grey-label: #676b6f;
  --nam-color-dark-grey: #3a3c3e;
  --nam-color-dark-grey1: #9fa9c812;

  --nam-color-grey-hr: #d3d3d3;
  --nam-color-light-bg: #f5f7f9;
  --nam-color-grey-100: #eaeef1;
  --nam-color-grey-200: #f9fafb;
  --nam-color-grey-300: #cdd4db;
  --nam-color-grey-400: #f9f9f9;
  --nam-color-grey-500: #D1D5DA;
  --nam-color-grey-700: #6A7180;
  --nam-color-grey-50: #f2f2f2;
  --nam-color-grey-600: #EFF6FF;
  --nam-color-grey-800: #F9F9FA;
  --nam-color-grey-scroll: #8b8b8b;

  // blue shades
  --nam-color-border-blue: #0048d2;
  --nam-color-blue-600: #115caa;
  --nam-color-blue-100: #ebf1fc;
  --nam-color-blue-200: #a3bbe8;
  --nam-color-blue-400: #eef3ff;
  --nam-color-blue-300: #295396;
  --nam-color-blue-500: #22394f;
  --nam-color-blue-700: #69869c4d;
  --nam-color-blue-800: #2787FB;
  --nam-color-blue-850: #ADD2FF;
  --nam-color-blue-900: #707b9c21;
  --nam-color-blue-950: #E7F2FF;
  --nam-color-blue-1000: #1F5FB1;
  --nam-color-blue-1001: #707b9c14;
  --nam-color-blueblack-1001: #37557314;
  // red shades
  --nam-color-red: #f00;
  --nam-color-red-1: #dc5858;
  --nam-color-red-100: #feecef;
  --nam-color-red-200: #ba0202;

  // green shades
  --nam-color-green-100: #ceefce;
  --nam-color-green-200: #455c3c;
  --nam-color-green-300: #63bb45;
  --nam-color-green-400: #1DA3A1;
  --nam-color-green-500: #3bd6ad;

  // orange shades
  --nam-color-orange-100: #fff6e8;
  --nam-color-orange-101: #666;
  --nam-color-orange-102: #7f8a96;
  --nam-color-orange-103: #9ba8b7;
  --nam-color-orange-104: #767a86;

  // shadows
  --nam-color-blue-shadow: #9baac80a;
  --nam-color-indigo-shadow: #586f9b0d;
  --nam-color-grey-shadow: #696e7714;
  --nam-color-blue-light: #1f3e70;
  --nam-color-blue-medium: #264e97;
  --nam-color-blue-medium-light: #007bff;
  --nam-color-dark-shadow: #7b89a32b;
  --nam-color-medium-shadow: #0003;
  --nam-color-yellow: #FAC656;
  --nam-color-grey-light: #F3F4F699;
  --nam-color-blue-lightblue: #396ea51a;
  --nam-color-darkblue-lightblue: #0c4a8e;
  --nam-color-greyblue-lightblue: #f3f4f68a;
  --nam-color-greyblueblack-lightblue: #3b48440f;
  --nam-color-greyblueblack-darkblue: #1d1d1d;
  --nam-color-greyblueblack-darkestblue: #0c4a8c;

}

$nam-color-primary: var(--nam-color-primary) !default;
$nam-color-primary-opaque: var(--nam-color-primary-opaque) !default;
$nam-color-secondary: var(--nam-color-secondary) !default;
$nam-color-white: var(--nam-color-white) !default;
$nam-color-black: var(--nam-color-black) !default;
$nam-color-light-bg: var(--nam-color-light-bg) !default;
$nam-color-grey-label: var(--nam-color-grey-label) !default;
$nam-color-light-grey: var(--nam-color-light-grey) !default;
$nam-color-dark-grey1: var(--nam-color-dark-grey1) !default;
$nam-color-dark-grey: var(--nam-color-dark-grey) !default;
$nam-color-border-blue: var(--nam-color-border-blue) !default;
$nam-color-grey-hr: var(--nam-color-grey-hr) !default;
$nam-color-blue-shadow: var(--nam-color-blue-shadow) !default;
$nam-color-indigo-shadow: var(--nam-color-indigo-shadow) !default;
$nam-color-grey-shadow: var(--nam-color-grey-shadow) !default;
$nam-color-grey-scroll: var(--nam-color-grey-scroll) !default;
$nam-color-blue-600: var(--nam-color-blue-600) !default;
$nam-color-blue-100: var(--nam-color-blue-100) !default;
$nam-color-grey-100: var(--nam-color-grey-100) !default;
$nam-color-blue-200: var(--nam-color-blue-200) !default;
$nam-color-blue-700: var(--nam-color-blue-700) !default;
$nam-color-blue-850: var(--nam-color-blue-850) !default;
$nam-color-blue-1001: var(--nam-color-blue-1001) !default;
$nam-color-blue-lightblue: var(--nam-color-blue-lightblue) !default;
$nam-color-darkblue-lightblue: var(--nam-color-darkblue-lightblue) !default;
$nam-color-greyblue-lightblue: var(--nam-color-greyblue-lightblue) !default;
$nam-color-greyblueblack-lightblue: var(--nam-color-greyblueblack-lightblue) !default;
$nam-color-greyblueblack-darkblue: var(--nam-color-greyblueblack-darkblue) !default;
$nam-color-greyblueblack-darkestblue: var(--nam-color-greyblueblack-darkestblue) !default;
$nam-color-orange-101: var(--nam-color-orange-101) !default;
$nam-color-orange-102: var(--nam-color-orange-102) !default;
$nam-color-orange-103: var(--nam-color-orange-103) !default;
$nam-color-orange-104: var(--nam-color-orange-104) !default;
$nam-color-blueblack-1001: var(--nam-color-blueblack-1001) !default;

$nam-color-blue-light: var(--nam-color-blue-light) !default;
$nam-color-blue-medium: var(--nam-color-blue-medium) !default;
$nam-color-blue-medium-light: var(--nam-color-blue-medium-light) !default;
$nam-color-blue-300: var(--nam-color-blue-300) !default;
$nam-color-grey-200: var(--nam-color-grey-200) !default;
$nam-color-grey-300: var(--nam-color-grey-300) !default;
$nam-color-grey-500: var(--nam-color-grey-500) !default;
$nam-color-grey-600: var(--nam-color-grey-600) !default;
$nam-color-grey-50: var(--nam-color-grey-50) !default;
$nam-color-grey-700: var(--nam-color-grey-700) !default;

$nam-color-red: var(--nam-color-red) !default;
$nam-color-red-1: var(--nam-color-red) !default;
$nam-color-red-100: var(--nam-color-red-100) !default;
$nam-color-red-200: var(--nam-color-red-200) !default;
$nam-color-green-100: var(--nam-color-green-100) !default;
$nam-color-green-200: var(--nam-color-green-200) !default;
$nam-color-orange-100: var(--nam-color-orange-100) !default;
$nam-color-blue-400: var(--nam-color-blue-400) !default;
$nam-color-grey-400: var(--nam-color-grey-400) !default;
$nam-color-blue-500: var(--nam-color-blue-500) !default;
$nam-color-green-300: var(--nam-color-green-300) !default;
$nam-color-green-500: var(--nam-color-green-500) !default;
$nam-color-green-400: var(--nam-color-green-400) !default;
$nam-color-dark-shadow: var(--nam-color-dark-shadow) !default;
$nam-color-medium-shadow: var(--nam-color-medium-shadow) !default;
$nam-color-yellow: var(--nam-color-yellow) !default;
$nam-color-blue-800: var(--nam-color-blue-800) !default;
$nam-color-grey-800: var(--nam-color-grey-800) !default;
$nam-color-blue-900: var(--nam-color-blue-900) !default;
$nam-color-blue-1000: var(--nam-color-blue-1000) !default;
$nam-color-blue-950: var(--nam-color-blue-950) !default;
$nam-color-grey-light: var( --nam-color-grey-light)!default;

$scad-grey--100: #F3F4F6;
$scad-grey--200: #D1D5DA;
$scad-grey--300: #9DA2AE;
$scad-grey--400: #6A7180;
$scad-grey--500: #4A5662;
$scad-grey--600: #364151;
$scad-grey--700: #1E2937;
$scad-grey--800: #D9D9D9;
