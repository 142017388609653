// .ng-select-content .ng-dropdown-panel .scroll-host {
//   // overflow-y: scroll;

//   &::-webkit-scrollbar {
//     width: 4px !important;
//     height: 4px !important;
//     border-radius: 16px;
//     background-color: transparent !important;
//   }

//   ::-webkit-scrollbar-track {
//     background: $nam-color-dark-grey !important;
//     padding-block: 20px !important;
//     margin-block: 20px !important;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: $nam-color-light-grey;
//     border-radius: 16px;
//   }
// }
@use "../../../assets/scss/abstracts/" as *;
.ng-select-content {
  .ng-dropdown-panel {
    .scroll-host {
      border-radius: 16px !important;

      &::-webkit-scrollbar {
        width: 4px !important;
        height: 4px !important;
        border-radius: 16px;
        background-color: transparent !important;
      }

      ::-webkit-scrollbar-track {
        background: $nam-color-dark-grey !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $nam-color-light-grey;
        border-radius: 16px;
      }
    }
  }

  box-shadow: 0 5px 10px 6px $nam-color-indigo-shadow;
  border: none;
  border-radius: 4px;

  .ng-dropdown-panel.ng-select-bottom {
    border-radius: 16px !important;
    border: none !important;
    margin-top: 8px !important;
  }

  .ng-dropdown-panel.ng-select-top {
    border-radius: 16px !important;
    border: none !important;
    margin-bottom: 8px !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: $nam-color-grey-label !important;
    padding: $spacer-3 !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: $nam-color-blue-100 !important;
  }
}
