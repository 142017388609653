@use '../abstracts/' as *;
.nam-modal-body {
  overflow-y: unset !important;
}

  .nam-modal-content {
    background-color: $nam-color-white;
    border-radius: 10px;
    width: 650px;

    &--bg {
      width: 890px;
      max-width: 890px;
    }
  }
  .nam-modal-header {
    border-bottom: 1px solid #d3d3d3;
    padding: 20px 40px;
  }

  .nam-modal-body {
    overflow-y: unset !important;
    padding: 20px 40px;
    overflow: auto;
    max-height: 400px;
    &.overflow-show {
      max-height: initial;
      overflow: visible;
    }
  }

  .nam-modal-footer {
    padding: 20px 40px ;
    display: flex;
    justify-content: flex-start;
    border-top: 1px solid #d3d3d3;

    nam-button {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }



  .nam-modal {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__title {
      font-size: 30px;
      font-weight: 600;
      color: $nam-color-dark-grey;
    }

    &__close {
      cursor: pointer;
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      gap: 4%;
      margin-bottom: 30px;
    }

    &__row:last-child {
      margin-bottom: 10px;
    }

    &__col-2 {
      width: 48%;
    }

    &__label {
      font-weight: 400;
      color: $nam-color-grey-label;
      margin-bottom: 10px;
      position: relative;

      &--required::after {
        content: "\002A";
        position: absolute;
        right: -10px;
        color: $nam-color-red;
      }
    }

    &__input {
      display: block;
      width: 100%;
      padding: 6px 12px;
      font-weight: 400;
      appearance: none;
      background-clip: padding-box;
      border: 1px solid $nam-color-light-grey;
      border-radius: 5px;
    }

    &__phone-field {
      position: relative;
    }

    &__error {
      color: $nam-color-red;
      font-size: 12px;
    }

    &__phone-input {
      padding-left: 30%;
    }

    &__phone-code {
      position: absolute;
      top: 16%;
      left: 3%;
      display: flex;
      align-items: center;
      gap: 8px;
      padding-right: 12px;
      border-right: 1px solid #eaeaea;
      color: #788587;
    }
  }

@include mobile {
  .nam-modal {
    &-header,
    &-body,
    &-footer {
      padding: 16px 24px;
    }

    &-footer {
      justify-content: space-between;
      nam-button {
        margin-right: 0px;
      }
    }

    &-content {
      width: 100%;

      &--bg {
        width: 100%;
      }
    }

    &__title {
      font-size: 24px;
    }

    &-body {
      max-height: 300px;
    }
  }

}
