// NAM CARD
@use "../../../assets/scss/abstracts/" as *;
.nam-card {
  background-color: $nam-color-white;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 12px;
  padding: $spacer-3;

  &__title {
    color: $nam-color-dark-grey;
    font-size: 32px;
    font-weight: 700;
  }

  &__desc {
    color: $nam-color-grey-label;
    font-size: 15px;
  }
}

// COMMON TITLE
.nam-common {
  &__title {
    font-weight: 600;
    font-size: 30px;
    color: $nam-color-dark-grey;
    text-transform: capitalize;
  }
}

@include tablet {
  .nam-card {
    &__title {
      font-size: 36px;
    }
  }
}

@include mobile {
  .nam-card {
    &__title {
      font-size: 36px;
    }
  }
}
