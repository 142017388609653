// Breakpoints
$bp-sm-mobile: 500px;
$bp-mobile: 767px;
$bp-tablet: 1024px;
$bp-tablet-lg: 1400px;

// font family
$poppins: "Poppins", sans-serif ;
$primary-font: $poppins !important;


// commonly-used
$spacer-0: 0;
$spacer-1: 4px;
$spacer-2: 8px;
$spacer-3: 16px;
$spacer-4: 24px;
$spacer-5: 32px;
$spacer-6: 40px;
$spacer-30px: 30px;
$spacer-15px: 15px;
$spacer-20px: 20px;
$spacer-6px: 6px;
$spacer-12px: 12px;
$spacer-10px: 10px;
$spacer-5px: 5px;
$spacer-2px: 2px;
$spacer-35px: 35px;
$spacer-14px: 14px;
$spacer-28px: 28px;
$spacer-18px: 18px;
$spacer-11px: 11px;
$spacer-1px: 1px;
$spacer-9px: 9px;
$spacer-7px: 7px;
$spacer-50px: 50px;
$spacer-22px: 22px;
$spacer-60px: 60px;
$spacer-80px: 80px;
$spacer-50px: 50px;
$spacer-25px: 25px;

$font-weight-600: 600;
