// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming


// ATOMS

// @use "@angular/material" as mat;
@use "./assets/scss/atoms/" as *;
@use "./assets/scss/molecules/" as *;
@use "./assets/scss/base/" as *;
// @use "assets/Icons/style.scss";
// Plus imports for other components in your app.

// bootstrap
// @import "bootstrap/scss/bootstrap";

// ng select


// @import "@ng-select/ng-select/themes/default.theme.css";

// ABSTRACT


// BASE

// // MOLECULES
// @import "assets/scss/molecules/modal";

// // Organism
// @import "assets/scss/organism/modal-body";
@import "ngx-toastr/toastr";
input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
