@use "../../../assets/scss/abstracts/" as *;
.nam-btn {
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  height: 40px;
  cursor: pointer;
  width: 140px;

  &--outline {
    color: $nam-color-primary;
    border: 1px solid $nam-color-primary;
    background-color: transparent;
    margin-right: 30px;
  }

  &--fill {
    color: $nam-color-white;
    background-color: $nam-color-primary;
    border: 1px solid $nam-color-primary;
  }
}
