@use "../../../assets/scss/abstracts/" as *;
.nam-input {
  &__control {
    border: 1px solid $nam-color-light-grey;
    border-radius: 4px;
  }

  &__label {
    text-transform: capitalize;
    color: $nam-color-grey-label;
    margin-bottom: 2px;
  }

  &__disabled {
    background-color: $nam-color-grey-400;
    pointer-events: none;
  }
}

input::placeholder {
  color: $nam-color-light-grey !important;
}
